<template>
    <el-dialog
            class="tanhcaung"
            title="智能处理"
            :visible.sync="handleDialogVisible"
            width="40%"
            :close-on-click-modal="tanchuan"
            :before-close="handleClose">
        <el-tabs @tab-click="labelSelection">
            <el-tab-pane label="按位置替换">
                <div class="center">
                    <el-form :rules="rules1" ref="searchForm1" :model="searchForm1" label-width="150px"
                             label-position="left">
                        <el-form-item label="需要替换的著录" prop="description">
                            <el-col :span="16">
                                <el-select size="small" style="width: 100%" v-model="searchForm1.description"
                                           placeholder="请选择需要替换的著录">
                                    <el-option v-for="item in headNameList" :key="item.value" :label=item.fieldName
                                               :value=item.fieldEname></el-option>
                                </el-select>
                            </el-col>
                        </el-form-item>
                        <el-form-item label="需要替换的位置" required>
                            <el-col :span="7">
                                <el-form-item prop="num1">
                                    <el-input size="small" min="0" v-model="searchForm1.num1"
                                              placeholder="请输入位置"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="2">至</el-col>
                            <el-col :span="7">
                                <el-form-item prop="num2">
                                    <el-input size="small" min="0" v-model="searchForm1.num2"
                                              placeholder="请输入位置"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                        <el-form-item label="需要替换成的内容" prop="content">
                            <el-col :span="16">
                                <el-input size="small" v-model="searchForm1.content"
                                          placeholder="请输入需要替换成的内容"></el-input>
                            </el-col>
                        </el-form-item>
                    </el-form>
                </div>
            </el-tab-pane>
            <el-tab-pane label="按内容替换">
                <div class="center">
                    <el-form :rules="rules2" ref="searchForm2" :model="searchForm2" label-width="150px"
                             label-position="left">
                        <el-form-item label="需要替换的著录" prop="descriptiona">
                            <el-col :span="16">
                                <el-select size="small" style="width: 100%" v-model="searchForm2.descriptiona"
                                           placeholder="请选择需要替换的著录">
                                    <el-option v-for="item in headNameList" :key="item.value" :label=item.fieldName
                                               :value=item.fieldEname></el-option>
                                </el-select>
                            </el-col>
                        </el-form-item>
                        <el-form-item label="需要替换的内容" required>
                            <el-col :span="16">
                                <el-form-item prop="replaceContent1">
                                    <el-input size="small" min="0" v-model="searchForm2.replaceContent1"
                                              placeholder="请输入内容"></el-input>
                                </el-form-item>
                            </el-col>
                            <!--                            <el-col :span="2">至</el-col>-->
                            <!--                            <el-col :span="7">-->
                            <!--                                <el-form-item prop="replaceContent2">-->
                            <!--                                    <el-input size="small" min="0" v-model="searchForm2.replaceContent2"-->
                            <!--                                              placeholder="请输入内容"></el-input>-->
                            <!--                                </el-form-item>-->
                            <!--                            </el-col>-->
                        </el-form-item>
                        <el-form-item label="需要替换成的内容" prop="content">
                            <el-col :span="16">
                                <el-input size="small" v-model="searchForm2.content"
                                          placeholder="请输入需要替换成的内容"></el-input>
                            </el-col>
                        </el-form-item>
                    </el-form>
                </div>
            </el-tab-pane>
            <el-tab-pane label="著录项合并">
                <div class="center">
                    <el-form :rules="rules3" ref="searchForm3" :model="searchForm3" label-width="150px"
                             label-position="left">
                        <el-form-item label="需要合并的著录项A" prop="mergeA">
                            <el-col :span="16">
                                <el-select size="small" style="width: 100%" v-model="searchForm3.mergeA"
                                           placeholder="请选择需要合并的著录项A">
                                    <el-option v-for="item in tableHead" :key="item.value" :label=item.fieldName
                                               :value=item.fieldEname></el-option>
                                </el-select>
                            </el-col>
                        </el-form-item>
                        <el-form-item label="连接符号" prop="connectionSymbol">
                            <el-col :span="16">
                                <el-input size="small" v-model="searchForm3.connectionSymbol"
                                          placeholder="请输入连接符号"></el-input>
                            </el-col>
                        </el-form-item>
                        <el-form-item label="需要合并的著录项B" prop="mergeB">
                            <el-col :span="16">
                                <el-select size="small" style="width: 100%" v-model="searchForm3.mergeB"
                                           placeholder="请选择需要合并的著录项B">
                                    <el-option v-for="item in tableHead" :key="item.value" :label=item.fieldName
                                               :value=item.fieldEname></el-option>
                                </el-select>
                            </el-col>
                        </el-form-item>
                        <el-form-item label="合并后著录项C" prop="mergeC">
                            <el-col :span="16">
                                <el-select size="small" style="width: 100%" v-model="searchForm3.mergeC"
                                           placeholder="请选择合并的著录项C">
                                    <el-option
                                            v-for="item in headNameList.filter(item1=>{return item1.fieldName != '成文日期'})"
                                            :key="item.value"
                                            :label=item.fieldName
                                            :value=item.fieldEname>
                                    </el-option>
                                </el-select>
                            </el-col>
                        </el-form-item>
                    </el-form>
                </div>
            </el-tab-pane>
            <el-tab-pane label="生成序号">
                <div class="center">
                    <el-form :rules="rules4" ref="searchForm4" :model="searchForm4" label-width="150px"
                             label-position="left">
                        <el-form-item label="生成序号的著录项" prop="serialNumber">
                            <el-col :span="16">
                                <el-select size="small" style="width: 100%" v-model="searchForm4.serialNumber"
                                           placeholder="请选择生成序号的著录项">
                                    <el-option v-for="item in headNameList" :key="item.value" :label=item.fieldName
                                               :value=item.fieldEname></el-option>
                                </el-select>
                            </el-col>
                        </el-form-item>
                        <el-form-item label="起始值" prop="startingValue">
                            <el-col :span="16">
                                <el-input size="small" v-model="searchForm4.startingValue"
                                          placeholder="请输入起始值"></el-input>
                            </el-col>
                        </el-form-item>
                        <el-form-item label="步长" prop="step">
                            <el-col :span="16">
                                <el-input size="small" v-model="searchForm4.step"
                                          placeholder="请输入步长"></el-input>
                            </el-col>
                        </el-form-item>
                        <el-form-item label="格式" prop="format">
                            <el-col :span="16">
                                <el-select size="small" style="width: 100%" v-model="searchForm4.format"
                                           placeholder="请选择格式">
                                    <el-option v-for="item in bookList" :key="item.value" :label=item.label
                                               :value=item.value></el-option>
                                </el-select>
                            </el-col>
                        </el-form-item>
                    </el-form>
                </div>
            </el-tab-pane>
            <el-tab-pane label="自动补值">
                <div class="center">
                    <el-form :rules="rules5" ref="searchForm5" :model="searchForm5" label-width="150px"
                             label-position="left">
                        <el-form-item label="需要补值的著录项" prop="serialNumber">
                            <el-col :span="16">
                                <el-select size="small" style="width: 100%" v-model="searchForm5.serialNumber"
                                           placeholder="请选择补值的著录项">
                                    <el-option v-for="item in headNameList" :key="item.value" :label=item.fieldName
                                               :value=item.fieldEname></el-option>
                                </el-select>
                            </el-col>
                        </el-form-item>
                        <el-form-item label="需要补值的方式" prop="mode">
                            <el-col :span="16">
                                <div class="leftduiqi">
                                    <el-radio-group v-model="searchForm5.mode">
                                        <el-radio label="1">固定著录项长度</el-radio>
                                        <el-radio label="2">固定补值</el-radio>
                                    </el-radio-group>
                                </div>
                            </el-col>
                        </el-form-item>
                        <el-form-item label="需要补值的位置" prop="position">
                            <el-col :span="16">
                                <div class="leftduiqi">
                                    <el-radio-group v-model="searchForm5.position">
                                        <el-radio label="1">著录项前面</el-radio>
                                        <el-radio label="2">著录项后面</el-radio>
                                    </el-radio-group>
                                </div>
                            </el-col>
                        </el-form-item>
                        <el-form-item label="补值的内容" prop="supplementaryContent">
                            <el-col :span="16">
                                <el-input size="small" v-model="searchForm5.supplementaryContent"
                                          placeholder="请输入内容"></el-input>
                            </el-col>
                        </el-form-item>
                        <el-form-item v-if="searchForm5.mode==1" label="固定长度" prop="fixedLength">
                            <el-col :span="16">
                                <el-input size="small" v-model="searchForm5.fixedLength"
                                          placeholder="请输入固定长度"></el-input>
                            </el-col>
                        </el-form-item>
                    </el-form>
                </div>
            </el-tab-pane>


            <el-row :gutter="20">
                <el-col :span="4" :offset="6">
                    <el-button @click="closePopup(1)" size="small" type="primary">执行</el-button>
                </el-col>
                <el-col :span="4" :offset="4">
                    <el-button @click="closePopup(0)" size="small">取消</el-button>
                </el-col>
            </el-row>
        </el-tabs>
    </el-dialog>
</template>

<script>
export default {
    name: "intelligentManagement",
    props: {
        handleDialogVisible: {
            type: Boolean,
            required: true,
        },
        zhiengchuli: {
            type: Array,
            required: true,
        },
    },
    data() {
        const zuidazhi = (rule, value, callback) => {
            if (value > 255) {
                return callback(new Error('255'))
            } else {
                callback()
            }
        }
        return {
            threeModuleId: "948974400588218368",
            headNameList: [],
            headName: [],
            tanchuan: false,
            tabPosition: false,
            shuju: {},
            xuanzetimage: 0,
            huoqushuju: [],
            bookList: [
                {
                    label: '%d',
                    value: '%d',
                },
                {
                    label: '%2d',
                    value: '%2d',
                },
                {
                    label: '%3d',
                    value: '%3d',
                },
                {
                    label: '%4d',
                    value: '%4d',
                },
                {
                    label: '%s-%4d',
                    value: '%s-%4d',
                },
            ], //选择需要替换的著录列表
            searchForm1: {
                description: '',
                num1: '',//替换位置
                num2: '',
                content: '',
            },
            rules1: {
                description: [
                    {required: true, message: '请选择需要替换的著录', trigger: 'change'}
                ],
                num1: [
                    {required: true, message: '请输入位置', trigger: 'blur'},
                    {pattern: /^[0-9]*[1-9][0-9]*$/, message: '位置必须为整数'},
                    {validator: zuidazhi, message: '位置应小于255位', trigger: 'blur'}
                ],
                num2: [
                    {required: true, message: '请输入位置', trigger: 'blur'},
                    {pattern: /^[0-9]*[1-9][0-9]*$/, message: '位置必须为整数'},
                    {validator: zuidazhi, message: '位置应小于255位', trigger: 'blur'}
                ],
                content: [
                    {required: true, message: '请输入需要替换成的内容', trigger: 'blur'}
                ],
            },
            searchForm2: {
                descriptiona: '',
                content: '',
                replaceContent1: '',
            },
            rules2: {
                descriptiona: [
                    {required: true, message: '请选择需要替换的著录', trigger: 'change'}
                ],
                content: [
                    {required: true, message: '请输入需要替换成的内容', trigger: 'blur'}
                ],
                replaceContent1: [
                    {required: true, message: '请输入内容', trigger: 'blur'}
                ],
            },
            searchForm3: {
                mergeA: '',//需要合并的著录A
                connectionSymbol: '',//连接符号
                mergeB: '',//需要合并的著录B
                mergeC: '',//合并后的著录项C
            },
            rules3: {
                mergeA: [
                    {required: true, message: '请选择需要合并的著录项A', trigger: 'change'}
                ],
                connectionSymbol: [
                    {required: true, message: '请输入连接符号', trigger: 'blur'}
                ],
                mergeB: [
                    {required: true, message: '请选择需要合并的著录项B', trigger: 'change'}
                ],
                mergeC: [
                    {required: true, message: '请选择合并的著录项C', trigger: 'change'}
                ],
            },
            searchForm4: {
                serialNumber: '',//生成序号的著录项
                startingValue: '',//起始值
                step: '',//步长
                format: '',//格式
            },
            rules4: {
                serialNumber: [
                    {required: true, message: '请选择生成序号的著录项', trigger: 'change'}
                ],
                startingValue: [
                    {required: true, message: '请输入起始值', trigger: 'blur'},
                    {pattern: /^[0-9]*[1-9][0-9]*$/, message: '起始值必须为整数'}
                ],
                step: [
                    {required: true, message: '请输入步长', trigger: 'blur'},
                    {pattern: /^[0-9]*[1-9][0-9]*$/, message: '步长必须为整数'}
                ],
                format: [
                    {required: true, message: '请选择格式', trigger: 'change'}
                ],
            },
            tableHead: [],
            searchForm5: {
                serialNumber: '',//补值的著录项
                mode: '1',//需要补值的方式
                position: '1',//补值的位置
                supplementaryContent: '',//补值的内容
                fixedLength: '',//固定长度
            },
            rules5: {
                serialNumber: [
                    {required: true, message: '请选择需要补值的著录项', trigger: 'change'}
                ],
                mode: [
                    {required: true, message: '请选择需要补值的方式', trigger: 'change'}
                ],
                position: [
                    {required: true, message: '请选择需要补值的位置', trigger: 'change'}
                ],
                supplementaryContent: [
                    {required: true, message: '请输入补值的内容', trigger: 'blur'}
                ],
                fixedLength: [
                    {required: true, message: '请输入固定长度', trigger: 'blur'},
                    {pattern: /^[0-9]*[1-9][0-9]*$/, message: '固定长度必须为整数'}
                ],
            },
        }
    },
    mounted() {
        this.getHeadNoInt();
        this.getHead()
    },
    methods: {
        getHead() {
            this.tableHead = [];
            this.$axios(this.api.dyyg.getByOriginalId + this.threeModuleId, '', "get").then((res) => {
                if (res.status) {
                    this.tableHead = res.data.filter(item => item.logicType == 0)
                }
            })
        },
        handleClose() {
            this.closePopup(0);
        },
        getHeadNoInt() {
            this.$axios(this.api.dyyg.getTableByResourcNoInt, {'resourceId': this.threeModuleId}, "get").then((res) => {
                if (res.status) {
                    this.headNameList = res.data
                }
            })
        },
        labelSelection(tabsExample) {
            this.$refs['searchForm1'].resetFields();
            this.$refs['searchForm2'].resetFields();
            this.$refs['searchForm3'].resetFields();
            this.$refs['searchForm4'].resetFields();
            this.$refs['searchForm5'].resetFields();
            this.xuanzetimage = tabsExample.index;
        },

        //num1为执行，0为取消
        closePopup(num) {
            this.shuju = {};
            if (this.xuanzetimage == 0) {
                if (num == 1) {
                    if (this.searchForm1.num1 > this.searchForm1.num2) {
                        this.$message.error('替换位置前的值应该比后面的值小')
                        return;
                    }
                }
                this.zhixing(num, 'searchForm1')
            } else if (this.xuanzetimage == 1) {
                this.zhixing(num, 'searchForm2')
            } else if (this.xuanzetimage == 2) {
                this.zhixing(num, 'searchForm3')
            } else if (this.xuanzetimage == 3) {
                if (num == 1) {
                    if (this.searchForm4.serialNumber == 'id' && this.searchForm4.format == '%s-%4d') {
                        this.$message.error('资源编号的格式不能为‘%s-%4d’')
                        return;
                    }
                }
                this.zhixing(num, 'searchForm4')
            } else if (this.xuanzetimage == 4) {
                this.zhixing(num, 'searchForm5')
            }
        },
        zhixing(num, formName) {
            if (num == 1) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$confirm('此操作将修改选择的数据, 是否继续?', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            let shujuji = JSON.parse(JSON.stringify(this.zhiengchuli));
                            if (formName == 'searchForm1' || formName == 'searchForm2') {
                                this.huoqushuju = [];
                                if (formName == 'searchForm1') { //按位置替换
                                    shujuji.map(item => {
                                        const oldId = item.id;
                                        let gaibianshu = [];
                                        let cishu = 0;
                                        let shujuzu = [];
                                        if (item[this.searchForm1.description]) {
                                            shujuzu = item[this.searchForm1.description].split('');
                                        } else {
                                            shujuzu = [];
                                        }
                                        if (shujuzu.length >= this.searchForm1.num1 && shujuzu.length < this.searchForm1.num2 || shujuzu.length < this.searchForm1.num1 || shujuzu.length == 0) {
                                            let duoyu = this.searchForm1.num2 - shujuzu.length;
                                            for (let i = 0; i <= duoyu; i++) {
                                                shujuzu.push('0');
                                            }
                                            console.log(shujuzu)
                                            shujuzu.map((item, index) => {
                                                if (cishu != 1) {
                                                    if (index == this.searchForm1.num1 - 1) {
                                                        gaibianshu.push(this.searchForm1.content)
                                                        cishu++;
                                                    } else {
                                                        gaibianshu.push(item)
                                                    }
                                                } else {
                                                    return;
                                                }
                                            })
                                        } else {
                                            shujuzu.map((item, index) => {
                                                if (index >= this.searchForm1.num1 - 1 && index <= this.searchForm1.num2 - 1) {
                                                    if (cishu != 1) {
                                                        gaibianshu.push(this.searchForm1.content)
                                                        cishu++;
                                                    } else {
                                                        return;
                                                    }
                                                } else {
                                                    gaibianshu.push(item)
                                                }
                                            })
                                        }
                                        item[this.searchForm1.description] = gaibianshu.join('');
                                        this.huoqushuju.push({
                                            id: oldId,
                                            menu: this.searchForm1.description,
                                            value: item[this.searchForm1.description]
                                        })
                                    })
                                } else if (formName == 'searchForm2') { //按内容替换

                                    shujuji.map(item => {
                                        const id = item.id
                                        console.log(item[this.searchForm2.descriptiona])
                                        if (!item[this.searchForm2.descriptiona]) {
                                            this.huoqushuju.push({
                                                id: id,
                                                menu: this.searchForm2.descriptiona,
                                                value: item[this.searchForm2.descriptiona],
                                            })
                                        } else {
                                            let shuju = item[this.searchForm2.descriptiona].split(this.searchForm2.replaceContent1);
                                            item[this.searchForm2.descriptiona] = shuju.join(this.searchForm2.content)
                                            this.huoqushuju.push({
                                                id: id,
                                                menu: this.searchForm2.descriptiona,
                                                value: item[this.searchForm2.descriptiona],
                                            })
                                        }
                                    })
                                }
                                console.log(this.huoqushuju)
                                this.$axios(this.api.dyyg.resourceHandle, {
                                    re: this.huoqushuju,
                                }, 'post').then(res => {
                                    if (res.status) {
                                        this.$message.success('修改成功')
                                    } else {
                                        this.$message.error('数据长度过长，修改失败')
                                    }
                                    this.$emit('Administration', 1)
                                    this.$refs[formName].resetFields();
                                })
                            } else if (formName == 'searchForm3') { //著录项合并
                                let seleA = this.tableHead.filter(item => {
                                    return item.fieldEname == this.searchForm3.mergeA
                                })
                                let seleB = this.tableHead.filter(item => {
                                    return item.fieldEname == this.searchForm3.mergeB
                                })

                                shujuji.map(item => {
                                    const id = item.id;
                                    if (seleA.length && seleA[0].fieldEname == 'resource_type') {
                                        item[this.searchForm3.mergeA] = this.$dictUtils.getDictLabel("resource_type", item[this.searchForm3.mergeA], '-')
                                    }
                                    if (seleA.length && seleA[0].fieldEname == 'resource_state') {
                                        item[this.searchForm3.mergeA] = this.$dictUtils.getDictLabel("resource_state", item[this.searchForm3.mergeA], '-')
                                    }
                                    if (!item[this.searchForm3.mergeA]) {
                                        item[this.searchForm3.mergeA] = ''
                                    }

                                    if (seleB.length && seleB[0].fieldEname == 'resource_type') {
                                        item[this.searchForm3.mergeB] = this.$dictUtils.getDictLabel("resource_type", item[this.searchForm3.mergeB], '-')
                                    }
                                    if (seleB.length && seleB[0].fieldEname == 'resource_state') {
                                        item[this.searchForm3.mergeB] = this.$dictUtils.getDictLabel("resource_state", item[this.searchForm3.mergeB], '-')
                                    }
                                    if (!item[this.searchForm3.mergeB]) {
                                        item[this.searchForm3.mergeB] = ''
                                    }

                                    item[this.searchForm3.mergeC] = item[this.searchForm3.mergeA] + this.searchForm3.connectionSymbol + item[this.searchForm3.mergeB];
                                    this.huoqushuju.push({
                                        id: id,
                                        menu: this.searchForm3.mergeC,
                                        value: item[this.searchForm3.mergeC]
                                    })
                                })
                                this.$axios(this.api.dyyg.resourceHandle, {
                                    re: this.huoqushuju,
                                }, 'post').then(res => {
                                    if (res.status) {
                                        this.$message.success('修改成功')
                                    } else {
                                        this.$message.error('数据长度过长，修改失败')
                                    }
                                    this.$emit('Administration', 1)
                                    this.$refs[formName].resetFields();
                                })
                            } else if (formName == 'searchForm4') { //生成序号
                                let ids = [];
                                shujuji.map(item => {
                                    ids.push(item.id)
                                })
                                this.$axios(this.api.dyyg.generateNumber, {
                                    content: this.searchForm4,
                                    ids: ids,
                                }, 'post').then(res => {
                                    if (res.status) {
                                        this.$message.success('修改成功')
                                    } else {
                                        this.$message.error('数据长度过长，修改失败')
                                    }
                                    this.$emit('Administration', 1)
                                    this.$refs[formName].resetFields();
                                })
                            } else if (formName == 'searchForm5') { //自动补值
                                let ids = [];
                                shujuji.map(item => {
                                    ids.push(item.id)
                                })
                                this.$axios(this.api.dyyg.supplementValue, {
                                    content: this.searchForm5,
                                    ids: ids,
                                }, 'post').then(res => {
                                    if (res.status) {
                                        this.$message.success('修改成功')
                                    } else {
                                        this.$message.error('数据长度过长，修改失败')
                                    }
                                    this.$emit('Administration', 1)
                                    this.$refs[formName].resetFields();
                                })
                            }
                        }).catch(() => {
                        });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            } else {
                this.$refs['searchForm1'].resetFields();
                this.$refs['searchForm2'].resetFields();
                this.$refs['searchForm3'].resetFields();
                this.$refs['searchForm4'].resetFields();
                this.$refs['searchForm5'].resetFields();
                this.$emit('Administration')
            }
        },
    }
}
</script>

<style scoped>
.el-dialog__body {
    padding: 10px 0px !important;
}

.center {
    text-align: center;
    padding-left: 65px;
}

.leftduiqi {
    text-align: left;
}
</style>